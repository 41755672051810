module.exports = [
  {
    id: '6',
    title: '空识',
    tags: ['谷歌', '全栈'],
    description: '五天100+题，怒进谷歌！',
    avatar: 'https://p.ipic.vip/r0v85n.jpg',
    body: `
## 背景

空识拿到了**Google的 offer**，和狗头做起了同事。

他一个礼拜刷了100多道题，然后面试 Google 终于通过了。这个经历还是蛮具有代表性的，分享给正在准备面试的你。

以下 Q 为 lucifer，A 为 空识。

![](https://p.ipic.vip/r0v85n.jpg)

## 采访

Q: 你是什么时候开始接触数据结构与算法（以下简称算法）的？

A: 大概是十年前读本科的时候……但是没有系统性的学习，只了解过一些特殊用途的算法（X算法，RSA算法,...）打算转码的时候才开始重头系统性的学习。

Q: 你是什么时候接触 91 天学算法（以下简称 91 天）的？从什么途径得知的？

A: Github上的题解->西法的公众号，在公众号里了解到的。

Q: 91 天有给你带来了什么样的变化么？

A: 我变秃了，也变强了。从一开始看到two sum觉得精妙，周赛做不出easy到现在能 **稳定写2-3题**。一开始oa撞原题都写不好到现在不少oa直接做也能拿到面试机会了。

尤其印象深刻的是 **一开始自己刷题，easy可能都要磨蹭个2-3小时研究相关的topic搞明白题解，最后一周刚好要准备狗厂面试，五天就刷了100+题……** 真的变秃了。

Q: 学习算法过程中有“顿悟”的时刻么？

A: 有呀，正如六祖慧能在《坛经》里说到的，“迷闻经累劫，悟则刹那间”，在各种重复思考卡壳之后再去看题解的点悟往往会有那种开了一扇门的感觉，然后就悟了哈哈。

Q: 你比较擅长的算法是什么？可以给大家简单分享一下么？

A: 其实是数学法，因为本身是学数学相关方向的，以前经常被同学分享这种很巧的题，所以练习得比较多。

Q: 有没有什么想和刚入坑算法的同学分享的？

A: 一定一定一定一定要坚持不要放弃，每天再难至少要刷1～3题（具体看是否脱产），每天发掘一个小技巧，91天就能学会很多很多东西。而且尤其是一开始，easy/medium的题都刷的很艰难需要很多时间，这个时候很容易会怀疑自己的能力/天赋，但是千万不要绝望不要放弃，因为绝望/放弃其实没有任何作用，咬紧牙关坚持下去是最重要的，超越了觉悟，就能看到希望。

另外还有就是万事从简，我最早转码纠结于以后到底该做什么纠结了很久，其实这个回头看，其实都要学的，直接捡一个比较主流找得到不少题解的语言开始最重要。

Q: 相对而言，你觉得 91 天哪里做的还不够好？应该如何改进？

A: 其实我觉得挺好的……感觉可能硬性要求开始的时候多打点卡比较好……？因为感觉一开始人比较容易有惰性，不想走出舒适区，可能需要一点push。

Q: 有没有什么面试和刷题技巧或建议给大家

A: 我觉得很重要的几个点：

1. 力扣官方有很好的 explore 卡片，那个可以很好的作为基础篇的补充，针对性的刷题可以帮你形成你自己的理解，有理解了刷题就快了。

2. 力扣的公司tag是可以按照频率选题的，刷高频题通常能够给你更直观的一个关于“这个公司到底是在考什么”的体验。

3. **打周赛，其实多少分不重要，能不能涨分也不重要，重要的是思路。** 所以你可以随便打开任何一期周赛，然后用力扣网站的模拟面试功能打。然后设立个目标，目标应该是差不多一个小时做二望三。

4. Think out loud。**面试的时候沟通很重要，所以一定要学会自言自语，写完主动手动跑一遍，想一次edge case，然后算一遍复杂度，可以的话甚至可以想一想这个题可以怎么改编怎么followup。**

> 注：北美的小伙伴我推荐用interviewing.io，我觉得练习很有效……

5. 一定一定一定一定要自信，振作起来，生活不容易，你是最棒的。    

## lucifer 总结

空识最后给的五个建议都非常的实用，手动点赞。另外空识的建议已经收到了。我们后期会加大 **push** 力度，努力营造一个积极向上的刷题氛围，fighting！

此外，空识提到了很多次打模拟面试或者周赛。其实我也建议大家过一遍 tag 后就先打 20 次周赛，做几道题都不重要，就是锻炼自己的做题能力以及给定压力下的思考反应等。
`
  },
  {
    id: '5',
    title: 'cabbage',
    tags: ['微软', '前端'],
    description: '自称是菜狗的微软大佬',
    avatar: 'https://p.ipic.vip/mlswbu.jpg',
    body: `

## 背景

最近得知 cabbage 拿到了**微软的 offer**，并在准备拿其他**更大**公司的 offer。就迫不及待地联系了他，希望他本人可以接受采访。于是这篇采访稿就和大家见面了。

cabbage 是一个做事情非常认真细致的人，对待工作一丝不苟，基本上事情交给他你就可以放心那种，这样的人谁不喜欢？我本人非常看好他，一定可以进更好的公司。

以下 Q 为 lucifer，A 为 cabbage。

![](https://p.ipic.vip/mlswbu.jpg)

## 采访

- Q: 你是什么时候开始接触数据结构与算法（以下简称算法）的？
- A: 20 年准备跳大厂的时候才开始的，半路出家的前端开发，以前根本都用不到算法所以也就没去学过。
- Q: 你是什么时候接触 91 天学算法（以下简称 91 天）的？从什么途径得知的？
- A: 通过 GitHub 上的 Lucifer 的算法 repo 关注的公众号，然后通过公众号了解到的。
- Q: 91 天有给你带来了什么样的变化么？
- A: 从基本啥都不会到对基本的数据结构有一点了解，以前只会做 easy 的题，现在有部分 medium 的题也能做出来了，主要是学会了套模板。
- Q: 学习算法过程中有“顿悟”的时刻么？
- A: 有吧，就突然有一天发现看到新题了会不自觉地去拿以前做题的套路去往上套，然后发现还真能用。
- Q: 你比较擅长的算法是什么？可以给大家简单分享一下么？
- A: 说不上擅长，就是套模板的题我都还挺喜欢的，比如说找排列组合的上来就先写个 dfs，找满足条件的 substring 就先上一个 sliding window，大部分的时候套上模板就能解决了。
- Q: 有没有什么想和刚入坑算法的同学分享的？
- A: 模板特别有用，不管会不会先背下来，背的同时就是一个理解的过程，91 算法的题解就可以帮你加深记忆也可以帮你更好的理解。另外刷题可以一次性把一个类型的都刷完，比如可以跟着 91 的分类刷，会更有效率。
- Q: 相对而言，你觉得 91 天哪里做的还不够好？应该如何改进？
- A: 同类型的题可以再多加几道加深记忆，容易到困难的过渡不知道还能不能再 smooth 一点，没有 CS 背景的对于有些类型的题理解起来不容易，可能需要更多的 background，比如 DP 和 back track 这样的。（因为一直在做前端开发的缘故，思维总是习惯性的线性思维，不容易绕过来）
- Q: 在你学习算法的过程中，对你帮助最大的学习资料是什么？
- A: LeetCode 的题解讨论区以及油管的一些视频。

> lucifer 注：有的 leetcode 题解和油管视频真的做的不错，我们也在《91 天学算法》的讲义中给大家进行了推荐。

- Q: 愿意把 91 天分享给你的朋友么？
- A: 愿意。

## lucifer 点评

收到 cabbage 的建议。 下一期《91 天学算法》西法打算做几点改变。

1. 目前正在和力扣官方合作，希望可以将《91 天学算法》整合力扣的**学习计划**和**LeetBook**。这样大家学习起来更加无缝，效率更高。届时也会有专门的交流群。
2. 增加**图**， **模拟与枚举** 的题目，以前这部分是自习的
3. 增加**排序** 章节
4. 前面增加了几道题目，相应其他章节需要删除题目，我打算将高频面试题删掉。
5. 部分题解不够完善，再次打磨。
6. 部分难度梯度跨度较大，增加一点缓冲题目或者对跨度较大的进行**大篇幅**讲解，尽量让大家不掉队。
    

    `
  },
  {
    id: '4',
    title: '小漾',
    tags: ['转码', '女神', '前端'],
    description:
      '只要参加过 91 天学算法的人都会知道的一个女神级人物，如今也是第四届 《91 天学算法》的讲师',
    avatar: 'https://p.ipic.vip/k2j4ld.jpg',
    body: `
- 时间：2021-05-21
- 被采访人：小漾
    
# 转行前端，成为无数刷题人的榜样。她是怎么做到的？ 

榜样的力量是无穷的，她就是无数 《91 天学算法》学员的榜样！
    
<img src="https://p.ipic.vip/922vbp.jpg" width="400">

Q: 你是什么时候开始接触数据结构与算法（以下简称算法）的？

A: 最初应该是刚开始学 JS 不久，那时候学了一点编程知识就喜欢在 **codewars** 上面玩一些入门题，也是这种解题的快感(菜鸟自嗨)让我继续学习编程，最终走上了前端不归路。后来丢下过一段时间，直到毕业工作大半年后才又开始断断续续在力扣上做一些题，不过那时并没有什么系统的学习计划。

Q: 你是什么时候接触 91 天学算法（以下简称 91 天）的？从什么途径得知的？

A: 从第一期就开始白嫖的老学员了，常年混迹在 Lucifer 前端群的我当然是第一时间就收到风声啦。

Q: 91 天有给你带来了什么样的变化么？

A: 让我的算法知识面**横向扩展**了一点。以前做题都是凭兴趣瞎做，跟着 91 练习之后，常见的算法专题都有机会接触到，虽然由于时间和个人精力的原因没有学得很深入(惭愧)，但起码对算法学习涉及哪些内容有了个大概的认识，应该算拿到了算法学习的入门券吧。

Q: 学习算法过程中有“顿悟”的时刻么？

A: 以前看 Lucifer 题解的时候经常会有“顿悟”的时候啊，他的题解和图解是我看过的最通俗易懂的了，虽然有时候还是不够小白友好，写得有点跳跃，可能这就是大佬和小白的代沟吧。后来我自己写题解的时候喜欢画图，也是因为 Lucifer 的影响呀。

Q: 你比较擅长的算法是什么？可以给大家简单分享一下么？

A: 个人还不认为自己有擅长的算法，有些算法会比较熟悉，所以简单题容易有思路，但没有透彻到可以把这类题型身上厚厚的伪装衣服脱下来，这方面 Lucifer 应该是专家吧，多看他的专题就好了。

Q: 有没有什么想和刚入坑算法的同学分享的？

A: 一个是做题的时候，**没有思路就不要死纠结啦，去看题解，去看相关的前置知识。学习是做题的前提呀，不要一上来就觉得简单题都不会做我是不是废了。**年轻人，这种想法会影响你拔剑的速度的呀。另一个是要学习分析算法的复杂度，有时候看力扣上的题解，基本都没有提到复杂度的，只有力扣官方和 Lucifer 的题解里都会分析。复杂度是衡量算法好坏的一个标准，学会分析复杂度就不会纠结为什么同样的代码在力扣上的提交击败率不一样啦。

Q: 相对而言，你觉得 91 天哪里做的还不够好？应该如何改进？

A: 对于小白同学来说，91 的进度可能会稍稍有点快吧，考虑一期分成入门和进阶两个班不？另外还有一个想法，在官网上加一个 feature，让 **学员可以在官网上预约 Q&A meeting，加上共享白板提供一个更好的答疑体验。** 不过，这个得是另外的价钱吧，嘿嘿。

Q: 愿意把 91 天分享给你的朋友么？

A: 我也想分享，但是大家都知道了。

Q: 目前的 offer 情况？

A: 今年1月份的时候跳槽了，那时就面试了两家公司，一个是现在的公司，另一个是阿里。拿了现在公司的 offer 之后就放弃了阿里的后续面试，没有像其他 91 大佬一样斩获各种大厂 offer，剧情是不是有点崩坏了。不过现在的公司虽然是小公司，当时面试的时候还被一个常春藤大佬面了一个小时算法，如果没有经历过 91，我是万万不可能撑住的，所以，**LONG LIVE 91 ALGORITHEM**.    
    
## lucifer 总结

这个经历太让人感动了。 虽然不是科班，但是理解能力和学习能力都非常优秀，并且还能将知识已通俗易懂的方式讲出来，这非常不容易。

最后小漾提的这个功能很有意思，lucifer 也会认真考虑的 ^_^。

**LONG LIVE 91 ALGORITHEM**.

`
  },
  {
    id: '1',
    title: 'kieda-tamashi',
    tags: ['美团', '携程', '字节', '硕士', '后端'],
    description: '无ACM经验，今年2月底开始刷Leetcode准备面试。',
    avatar: 'https://p.ipic.vip/pv45qz.jpg',
    body: `
- 时间：2021-05-05
- 被采访人：kieda-tamashi

# 无ACM经验，今年2月底开始刷Leetcode准备面试。


<img src="https://p.ipic.vip/62aqfv.jpg" width="400">

Q: 你是什么时候开始接触数据结构与算法（以下简称算法）的？
  
A:现在水硕应届。课程的话，大二学数据结构，大三学算法。无ACM经验，今年2月底开始刷Leetcode准备面试。

Q: 你是什么时候接触 91 天学算法（以下简称 91 天）的？从什么途径得知的？

A:今年2月底，好像是搜索啥搜到的，看了讲义感觉不错。

Q: 91 天有给你带来了什么样的变化么？

A:至少看到题有感觉了，虽然找工作很晚没进AT，但至少没失业

Q: 学习算法过程中有“顿悟”的时刻么？

A: 二分，边界弄得很清楚了。

Q: 你比较擅长的算法是什么？可以给大家简单分享一下么？

A:没什么擅长的，但比较喜欢做没有递归、stack相关的题目，往往不那么抽象，可以画图。

Q: 有没有什么想和刚入坑算法的同学分享的？

A: 早点开始，坚持每日一题，就算是抄的。

Q: 相对而言，你觉得 91 天哪里做的还不够好？应该如何改进？

A: 我觉得Github打卡有点太不好使了，也不怎么push和有可视激励，leetcode打卡还有积分显示，成长感明显，这边啥都没。

Q: 愿意把 91 天分享给你的朋友么？

A: 已经分享。 

## lucifer 总结

他的 B 站账号是： https://space.bilibili.com/1836395 ，感兴趣的小伙伴可以关注一波啦~
      `
  },
  {
    id: '2',
    description: '一个很可爱，元气满满的转码少女~',
    tags: ['谷歌', '商科转码', '全栈'],
    title: '易潇',
    avatar: 'https://p.ipic.vip/a4kh9a.jpg',
    body: `
- 时间：2021-05-07
- 被采访人：易潇
    
# 努力上岸的元气少女

力扣加加，一个努力做西湖区最好的算法题解的团队。**91 天学算法**是力扣加加举办的一个算法训练活动，通过良好的学习计划 和 91 天的集中学习，帮助大家摆脱困境，征服算法。

现在 《91 天学算法》已经举办了四期了，[第四期](https://mp.weixin.qq.com/s?__biz=MzI4MzUxNjI3OA==&mid=2247488668&idx=1&sn=001d53b788fb21280f9e45cadb7b4fae&chksm=eb88d885dcff5193f17725eb4b6b56cbbe406d8426bdc0dd5512253e2a0ef525358f94d4ab5a&token=2115509684&lang=zh_CN#rd "91 天学算法第四期")的活动时间是 **2021-05-10 到 2021-08-08**。

每一期都有很多优秀学员，我想将这些人的学习经验分享给大家，帮助大家少走弯路。由于我开辟了这个 **91 天学算法优秀学员专访** 活动。

这次，我们邀请到了远在美国的转码少女 - 易潇，一个元气满满的活力女孩。来看看她是如何学习的吧！

<img src="https://p.ipic.vip/srb63k.jpg" width="400">

## 采访

Q: 你是什么时候开始接触数据结构与算法（以下简称算法）的？

A: 从很久之前就听说过数据结果与算法，但正式接触是打算转码的时候开始的。

Q: 你是什么时候接触 91 天学算法（以下简称 91 天）的？从什么途径得知的？

A: 我是从第二期就开始接触91的，大概是关注公众号的时候关注到的。

Q: 91 天有给你带来了什么样的变化么？

A: 91让我从觉得用哈希表O(N)写 two sum很惊奇到了现在可以迷迷糊糊的状态下手写完堆（狗头

最近因为刷了91，在做几个公司笔试初试(online coding challenge)的时候**感觉很简单**，也对自己的即将到来的面试更加有自信了~

Q: 学习算法过程中有“顿悟”的时刻么？

A: 经常顿悟 -- **顿悟源自于重复和每次重复带来的更深层次的理解。**

Q: 你比较擅长的算法是什么？可以给大家简单分享一下么？

A: 比较擅长的算法是看小漾怎么写的，然后默默的取长补短（就是都抄一遍的意思）

Q: 有没有什么想和刚入坑算法的同学分享的？

A: 我觉得刚入坑的同学们一定要学会处理自己的 “迷茫” 以及这种情绪带来的 “我不想做，这太难了”。我觉得在学习的过程中，只要能在自己感到迷茫的时候坚持再坚持，总会有那么一个 “顿悟”的时刻，然后就会觉得**自己的坚持是值得的**。
我在正式学习编程之前的两三年里，也经常想过转码，但每次都会因为 “选什么语言” 或者 “初始化环境设定” 太难了等这种原因放弃。**现在其实回头看，这些问题其实都很简单。**

Q: 相对而言，你觉得 91 天哪里做的还不够好？应该如何改进？

A:我希望 91 有更多的个人答题跟踪和一起打卡的人的榜单，这样大家可以相互鼓（shi）励（ya）

Q: 愿意把 91 天分享给你的朋友么？

A:当然愿意啦！我觉得新手入门的时候最难的就是选择 “学什么” ， 而  91 恰好解决了这个问题。加入了 91 之后，只要根据  91 已经制定好的计划每天努力就可以了。而且问题是循序渐进的，对新手非常友好~ 再加上有这么多同学和超级棒的讲师们一起，我觉得 91 是最棒的刷题计划啦！
 


## lucifer 总结

学习东西是需要循序渐进的。在这个循序渐进的路上会一次次地“顿悟”。如果你有一个良好的学习习惯和学习计划，那么我相信你效率一定很高，那么答案你理想的高度也是时间的问题罢了。而如果你缺乏这样的学习习惯，不知道怎么去学习算法，可以试试 91 天学算法哦，活动介绍在文末的链接中，也可以直接在公众号力扣加加中回复 91 获取。
`
  },
  {
    id: '3',
    description: '擅长图解的小哥哥',
    tags: ['明星学员', '图解算法'],
    title: '蘑菇沏茶',
    avatar: 'https://p.ipic.vip/exqmkw.jpg',
    body: `
- 时间：2021-05-09
- 被采访人：蘑菇沏茶
    
# 擅长图解的小哥哥

## lucifer 序

蘑菇沏茶在群里很活跃，也经常在仓库中看到他的打卡，他的题解都很认真，质量很高，也入选了很多次的精选题解，这样慢慢就对他有了印象。 后来我邀请他给我投稿，内容非常不错，大家可以看下，感受一下作者的算法能力和文笔。

[图解数据结构](https://mp.weixin.qq.com/s/IdzUT8QvaWNSBca944FR0g)

他本人学习算法时间不算长，也经历了很多人经历过的开始阶段，因此他的经历对很多人来说，还是非常有参考价值的。

## 正文

![](https://p.ipic.vip/eze6g8.jpg)

Q: 你是什么时候开始接触数据结构与算法（以下简称算法）的？

A: 最开始接触算法的话，是大二的时候。那时候只是把它当做一门课程，抱着及格万岁的心态，并没有觉得有多重要，实际上在日常开发中，也没有使用到算法相关，也就更别提意识到算法的一个重要性了。

Q: 你是什么时候接触 91 天学算法（以下简称 91 天）的？从什么途径得知的？

A: 接触到91天，还要从找实习说起，因为研究生期间主要是做NLP的，当时是在 **女朋友** 的催促下，随便找了一份算法的实习，实习之后发现和我想象中的差距非常大，也不是自己喜欢的。在某乎上看到许多大佬的求职之路，都提到了算法的重要性，也都提到leetcode，于是开始效仿大佬开始刷leetcode，但是刚开始的时候效率非常低，力扣有接近2000道题目，不知道从何刷起，非常迷茫，说来也巧，在某一天阅读社区题解的时候，发现了西法老师的一篇题解，这一看，简直不要太清晰明了，代码简洁，思路清晰，立马就悟了，后面关注了西法老师的“力扣加加”公众号，从里面得知了91天这一活动，当时是第二期。加入的时候，群里已经有200人左右了，后面一直有人加入，慢慢到了500人。在西法老师的系统讲解和整体氛围下，也慢慢开始喜欢上了算法，在刷算法的过程中，也是意识到算法的重要性，在这里必须感谢西法老师，开发并不只是简单地crud，比如在搜索的时候，我们该选取什么样的数据结构，又要以一个什么样的方法去进行搜索，别看两个算法在同一个例子中时间相差不多，但是**一旦数据范围变大，一个好的算法和数据结构对时间的提升是非常巨大的。**

Q: 91 天有给你带来了什么样的变化么？

A: 91天学算法给我带来的变化，首先就是对算法有了系统的认识，以前在课本上只学习了动态规划、贪心、递归、分治等，而且老师也只是简单讲解，没有深入，西法给我展示了算法的魅力。然后就是，我跟了91天两期，从第一期对算法有了系统认识，到第二期有了深一层次的认识，如果说刚开始的时候是照葫芦画瓢，那现在就是开始在照瓢画葫芦了，对一道题，除了把它解出来之外，会主动深入思考这里面包含的知识点，也会和其他的解法进行比较，去理解别人的做题时的思路，而不再是看懂。最后就是说一个最明显的提升，**跟完一期多的时候，参加了一些大厂的笔试，虽然题目没有原题，但考察的内容基本都有在91天中提到，所以学完91天，算是拿到了大厂的入场券**。以后只要91天还办下去，我就会一直跟下去，在这里，因为，现在已经不只是为了找到一份好的实习或者工作而去学算法，是真的已经喜欢上了算法，喜欢上解出一道题时获得的喜悦，在91天中，有许多和我这样爱好算法的同学，大家在刷题的路上一期成长，真的是一件非常快乐的事情。有一段时间无比期待每日一题的到来，**十二点准时侯在电脑前。**

Q: 学习算法过程中有“顿悟”的时刻么？

A: 要说顿悟的话，首先有一个就是比较搞笑的，刚开始刷算法，把提交后的运行时间当做评判标准，觉得时间快的就是好算法，后来西法的一句话让我印象非常深刻，就是衡量一个算法的好坏的标准是时间复杂度与空间复杂度，因为每次提交的时候，可能会由于网络波动、测试案例等因素导致每次时间不一样。再说其他的，比如做二分类题目的时候，除了对输入进行二分，还可以对结果进行二分，再比如再做树专题的时候，搜索无非就DFS和BFS，然后再决定是否带信息进行遍历，这些在西法的力扣加加公众号的总结中都可以看到；还有一个题没有思路的时候，可以根据题目给的关键字和数据范围去。其实顿悟这个词本来就很玄，我更愿意把顿悟理解为量变引起质变这一过程，题目做多了，很多方法自然而然就悟了，在做动态规划的时候，听到西法的一句 **“我做题的时候都是只考虑前一个状态和现在的状态”，原话记不太清了，差不多就是这个意思，看到这句话，就突然会悟了，这不就是满足动态规划的最优子结构性质嘛，这种时刻还有一些，基本上大多是已经有了一个差不多的理解，然后听到一句话或者看到一句话，然后就明白了。**

Q: 你比较擅长的算法是什么？可以给大家简单分享一下么？

A: 比较擅长的话，应该还没到擅长的地步吧，在西法老师面前不敢造次，但是**对常用的算法还算熟悉了**。比如二分算法，要么是对输入进行二分，要么是对结果进行二分，然后对二维数组进行二分的话，一般都是从斜对角线的两个顶点作为起始端点，还有一类题目就是使用二分保持数据的有序性；比如单调栈或者单调队列，存储的不仅仅可以是元素，也可以是元素对应的索引；对于回溯类题目，最重要的就是确定剪枝条件了，‘’毕竟只要剪枝剪得好，完美AC少不了‘’，主要用来解决一些排列组合问题，比如题目明确要求需要返回所有的可能；对于搜索类，常见的就是DFS和BFS，最重要的一点是不存在环路，或者有提前终止的条件，这类题目一般都是树或者图，当然有的时候，不一定会告诉你这是一棵树或者一张图，这种时候就需要自己体会题意了；对于存在环路的问题，一般会用到并查集，并查集要注意的只有两点，一个是按秩合并，一个是路径压缩；对于双串类问题，十有八九可以使用动态规划去求解；对于求解topK类问题，使用堆，或者在每个时刻，必须取最小或者最大，而这个最小最大在每个时刻会发生改变，那么也可以使用堆。

Q: 有没有什么想和刚入坑算法的同学分享的？

A: 无它，唯手熟尔。对于学习算法，没有太多的技巧，做得多了，见的多了，自然而然就会了。我刚开始做题的时候会画图帮助理解，但是现在很少画了，**因为熟了，图在心中。**

Q: 相对而言，你觉得 91 天哪里做的还不够好？应该如何改进？

A: 91天是一个很好地活动，物超所值，一杯喜茶就能得到大厂的入门卷，爱了爱了啊。就对于我个人而言，我希望看到一些**算法在实际场景的应用。**

> 西法收到了 ^_^

Q: 愿意把 91 天分享给你的朋友么？

A: 当然愿意，在第三期的时候，拉动我的室友加入到91天学算法活动中。
    
    
    `
  }
]
